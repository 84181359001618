<template>
  <div class="mx-auto">
    <TitleWrapper
      :filter-option="false"
      title="SYLLABUS_AUTOMATOR_PERMISSION"
      tooltip-title="SYLLABUS_AUTOMATOR_PERMISSION"
      :display-breadcrumb="true"
    ></TitleWrapper>
    <div class="minHieght80 flex-col">
      <div v-if="!loading">
        <SyllabusTemplateCheckbox
          v-for="(val, indexVal) in settingsArray"
          :key="indexVal"
          class="mt-6"
          :title="indexVal"
          parent-module="syllabus-template"
          :modules-array="val"
          @updateSettings="getSettings"
        />
      </div>
      <Loader v-else class="mt-28" :content="true" />
    </div>
  </div>
</template>

<script>
/**
 * @notes IMPORTANT NOTES
 * index ACTION FOR VIEW
 * create ACTION FOR MARK
 *  */
/* COMPONENTS */
import TitleWrapper from '@components/TitleWrapper.vue'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'
import SyllabusTemplateCheckbox from '@src/components/Settings/attandance-settings/PermissionSettingsCheckbox.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { removeExtraRolePermissions } from '@utils/permissions'
/* 3rd party Component */
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    TitleWrapper,
    SyllabusTemplateCheckbox,
    Loader,
  },
  page: {
    title: 'Syllabus Template Permission',
    meta: [
      {
        name: 'description',
        content: 'Syllabus Template',
      },
    ],
  },
  data: function () {
    return {
      settingsArray: [],
      loading: false,
      excludeRoleSettings: [
        TENANT_ROLES.SECTION_ADMIN,
        TENANT_ROLES.SECTION_MODERATOR,
        TENANT_ROLES.CAMPUS_MODERATOR,
      ],
    }
  },

  computed: {
    ...mapState('layout', ['currentCampusScope', 'currentSectionScope', 'currentClassScope']),
  },
  watch: {
    currentCampusScope: {
      handler() {
        this.getSettings()
      },
      deep: true,
    },
    currentSectionScope: {
      handler() {
        this.getSettings()
      },
      deep: true,
    },
    currentClassScope: {
      handler() {
        this.getSettings()
      },
    },
  },
  mounted() {
    this.getSettings()
  },

  methods: {
    async getSettings() {
      this.loading = true
      const [res, err] = await this.getSyllabusTemplateSettings()
      removeExtraRolePermissions(this.excludeRoleSettings, res.data)
      this.settingsArray = res.data
      this.loading = false
    },
    ...mapActions('settings', ['getSyllabusTemplateSettings']),
  },
}
</script>
